<template>
    <div class="catalog">
        <div class="catalog-navigator" v-if="catalog.length > 1">
            <p class="catalog-type">Выберите тип {{tech_name}}:</p>
            <div class="catalog-group">
                <a :id="'catalog_'+cat.slug" @click="changeCat(index)" v-for="(cat, index) in catalog"
                   :class="[index === selectedCat ? 'active' : ''] + ' catalog-group-link'"
                   data-groupe_id="">
                    {{cat.title}}
                </a>
            </div>
        </div>
        <div class="row" v-if="catalog[selectedCat].tech.length">
            <div v-for="(tech, index) in catalog[selectedCat].tech.slice(0, techLimit)"
                 class="col-xl-3 col-lg-4 col-sm-6 d-flex align-items-stretch">
                <div class="card">
                    <div class="card-body">
                        <div :style="'background-image: url(\'/storage/content/'+tech.image_thumb+'\')'"
                             class="tech-image text-center">

                        </div>
                        <div class="tech-title">{{tech.title}}</div>
                        <div class="tech-spec">
                            <ul v-if="tech.spec">
                                <li v-for="(spec,index) in tech.spec">
                                    <span>{{index}}</span><span>{{spec}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="tech-price" v-if="card_footer === 1">
                            <p class="price">
                                <span class="price-text">Стоимость за 1 ч.</span>
                                <span class="price-value">
                                    <span>{{Number(tech.price).toFixed(0)}} ₽</span>
                                </span>
                            </p>
                            <p class="price">
                                <span class="price-text">Стоимость за смену 8 ч.</span>
                                <span class="price-value">
                                        <span class="old">{{Number(tech.price_s).toFixed(0)}} ₽</span>
                                        <span class="new">{{Number(tech.discount_s).toFixed(0)}} ₽</span>
                                    </span>
                            </p>
                        </div>
                        <div class="tech-price" v-if="card_footer === 2">
                            <p class="price">
                                <span class="price-text">Стоимость за смену 8 ч.</span>
                                <span class="price-value">
                                        <span class="new">от {{Number(tech.discount_s).toFixed(0)}} ₽</span>
                                    </span>
                            </p>
                        </div>
                        <div class="tech-price" v-if="card_footer === 3">
                            <p class="price">
                                <span class="price-text">Стоимость за 1 ч.</span>
                                <span class="price-value">
                                        <span class="new">от {{Number(tech.discount).toFixed(0)}} ₽</span>
                                </span>
                            </p>
                        </div>
                        <div class="tech-price" v-if="card_footer === 4">
                            <p class="price">
                                <span class="price-text">Стоимость за смену 8 ч.</span>
                                <span class="price-value">
                                        <span class="old">{{Number(tech.price_s).toFixed(0)}} ₽</span>
                                        <span class="new">от {{Number(tech.discount_s).toFixed(0)}} ₽</span>
                                    </span>
                            </p>
                        </div>
                        <div class="tech-price" v-if="card_footer === 5">
                            <p class="price">
                                <span class="price-text">Стоимость за 1 ч.</span>
                                <span class="price-value">
                                    <span class="old">{{Number(tech.price).toFixed(0)}} ₽</span>
                                        <span class="new">от {{Number(tech.discount).toFixed(0)}} ₽</span>
                                </span>
                            </p>
                        </div>
                        <div class="tech-price" v-if="card_footer === 6">
                            <p class="price">
                                <span class="price-text">Стоимость за 1 ч.</span>
                                <span class="price-value">
                                    <span class="old">{{Number(tech.price).toFixed(0)}} ₽</span>
                                        <span class="new">{{Number(tech.discount).toFixed(0)}} ₽</span>
                                </span>
                            </p>
                            <p class="price">
                                <span class="price-text">Стоимость за смену 8 ч.</span>
                                <span class="price-value">
                                        <span class="old">{{Number(tech.price_s).toFixed(0)}} ₽</span>
                                        <span class="new">{{Number(tech.discount_s).toFixed(0)}} ₽</span>
                                    </span>
                            </p>
                        </div>
                        <div class="tech-button">
                            <a class="open_order_tech" :id="'open_order_tech_'+tech.id" @click="orderTech(index)">{{text_on_button}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="catalog-footer" v-if="catalog[selectedCat].tech.length > techLimit">
            <a class="load-more" @click="loadMore">Показать следующие <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                <path d="M6.1018 8C5.02785 8 4.45387 9.2649 5.16108 10.0731L10.6829 16.3838C11.3801 17.1806 12.6197 17.1806 13.3169 16.3838L18.8388 10.0731C19.5459 9.2649 18.972 8 17.898 8H6.1018Z" fill="#212121"/>
            </svg></a>
        </div>
    </div>

</template>

<script>
    export default {
        props: [
            'catalog',
            'tech_name',
            'card_footer',
            'text_on_button'
        ],
        data() {
            return {
                selectedCat: 0,
                techLimit: 8,
                loadMoreLimit: 8,

            }
        },
        computed: {},
        methods: {
            loadMore: function () {
                this.techLimit += this.loadMoreLimit;
            },
            changeCat: function (index) {
                this.selectedCat = index;
                this.techLimit = this.loadMoreLimit;
            },
            orderTech: function (index) {
                const vm = this;

                let tech = this.catalog[this.selectedCat].tech[index];

                document.querySelector('[name="tech_id"]').value = tech.id;
                document.querySelector('#popup-tech-form button')
                    .innerHTML = 'Заказать ' + tech.title[0].toLowerCase() + tech.title.slice(1);
                document.querySelector('#popup-tech-form .selected-tech-name')
                    .innerHTML = tech.title;
                document.querySelector('#popup-tech-form .selected-tech-image')
                    .style.backgroundImage = 'url("/storage/content/' + tech.image + '")';


               /* $.fancybox.open([
                    {
                        wrapCSS: 'openPopUp',
                        touch: false,
                        src: '#popup-tech-form',
                        titleShow: false,
                        autoScale: true,
                        openEffect: 'fade',
                        //modal: true
                    }
                ]);*/
            }
        },
        mounted() {
            if (window.innerWidth < 576) {
                this.techLimit = 4;
                this.loadMoreLimit = 4;
            }

            let hash = window.location.hash;
            if(hash.length) {
                if(document.querySelector(hash))
                    document.querySelector(hash).scrollIntoView();
            }

            /*console.log(this.catalog)
            console.log(this.tech_name)
            console.log('Component mounted.')*/
        }
    }
</script>
